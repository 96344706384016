<template>
  <div class="unboxing-detail">
    <h2 class="page-title">晒单详情</h2>
    <loading v-if="loading"/>
    <template v-else>
      <header class="mb-3">
        <b-row>
          <b-col cols="auto" class="pr-0">
            <avatar :src="unboxingItem.creator.avatar" size="3em"/>
          </b-col>
          <b-col>
            <div class="user-info">
              <span class="time text-muted float-right">
                发布于
                <datetime :value="unboxingItem.createAt"/>
              </span>
              <b class="nickname">{{unboxingItem.creator.name}}</b>
              <div v-if="unboxingItem.book">
                <b-badge>{{unboxingItem.bookType | productName(true)}}</b-badge>
                <b-badge variant="primary">{{unboxingItem.book.type | bookType}}</b-badge>
                <b-badge variant="success">{{unboxingItem.book.bindingName}}</b-badge>
              </div>
            </div>
          </b-col>
        </b-row>
      </header>
      <div class="content">
        <p class="text">{{unboxingItem.content.trim()}}</p>
        <div class="images" v-if="unboxingItem.picList">
          <square class="mt-3" alt :src="image" img-size="800" height="auto"
                  v-for="image in unboxingItem.picList" :key="image"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import route from '@/mixins/route-data'

export default {
  name: 'unboxingDetail',
  mixins: [route('unboxingItem')],
  methods: {
    onLoad() {
      this.$setTitle(this.unboxingItem.creator.name + '的微信书晒单')
    }
  }
}
</script>

<style scoped lang="scss">
.unboxing-detail {
  max-width: 640px;

  .text {
    white-space: pre-wrap;
  }

  .images {
    text-align: center;
  }

  img {
    margin: .5rem 0;
    max-width: 100%;
    width: 480px;
    border-radius: 4px;
    border: 1rem solid #eee;
  }
}
</style>
