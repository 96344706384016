<template>
  <div class="unboxing">
    <header>
      <div class="content">
        <div class="slogan" v-image-text>心书，只为更多人温暖的回忆</div>
        <div class="text">
          已有
          <span class="text-primary">{{latestUserCount}}</span>
          人完成自己的第一本微信书
        </div>
      </div>
    </header>

    <section class="masonry">
      <b-card class="mt-n5" v-if="hasLogin">
        <b-row align-v="center">
          <b-col>
            <h5>我也要去晒单</h5>
            <div>晒单赢取A4轻奢杂志册...</div>
          </b-col>
          <b-col cols="auto">
            <b-btn variant="warning" to="/orders">
              <fa icon="camera" fw/>
              去晒单
            </b-btn>
          </b-col>
        </b-row>
      </b-card>

      <masonry ref="masonry" :cols="cols" :gutter="20" :key="'cols-' + cols">
        <template v-if="loading">
          <b-card v-for="i in 6" :key="'ske' + i">
            <header class="mb-3">
              <div class="float-left mr-2" style="width: 3em;">
                <ske type="square" width="3em"/>
              </div>
              <ske width="5em" block/>
              <ske width="8em"></ske>
            </header>

            <ske block/>
            <ske block/>
            <ske block/>

            <grid cols="2">
              <grid-item>
                <ske type="square"/>
              </grid-item>
              <grid-item>
                <ske type="square"/>
              </grid-item>
            </grid>

            <grid cols="2">
              <grid-item>
                <ske type="square"/>
              </grid-item>
              <grid-item>
                <ske type="square"/>
              </grid-item>
            </grid>
          </b-card>
        </template>

        <template v-else>
          <b-card v-for="item in cashbacks" :key="item.id" :data-rank="item.rank">
            <header class="mb-3">
              <avatar size="3em" :src="item.creator.avatar" class="float-left mr-2"/>
              <datetime class="float-right text-muted small" :value="item.createAt"/>
              <div class="nickname text-overflow">{{item.creator.name || '匿名用户'}}</div>
              <div class="text-overflow text-muted">
                <b-link style="color: inherit" :to="'/unboxing/' + item.id">《{{item.book.name}}》</b-link>
              </div>
            </header>

            <div class="card-text mb-1" v-expand
                 :class="{expanded: expanded === item.id}">{{item.content}}
            </div>

            <div style="display: none;">
              <b-link @click="expanded = expanded === item.id ? null : item.id">
                {{expanded === item.id ? '收起' : '展开'}}全文
                <fa :icon="expanded === item.id ? 'chevron-up' : 'chevron-down'"/>
              </b-link>
            </div>

            <image-zone class="mt-2" float
                        :cols="item.picList.length !== 4 ? 3 : 2"
                        :initial="item.picList.length !== 4"
                        :value="item.picList"/>

            <footer class="clearfix text-right">
              <div class="float-left" v-if="item.rank > 0">
                <b-badge class="rank-3" v-if="item.rank === 3">
                  <fa icon="gem"/>
                  钻石晒单
                </b-badge>
                <b-badge class="rank-2" v-if="item.rank === 2">
                  <fa icon="medal"/>
                  黄金晒单
                </b-badge>
                <b-badge class="rank-1" v-if="item.rank === 1">
                  <fa icon="trophy"/>
                  白银晒单
                </b-badge>
              </div>

              <template v-if="item.book">
                <b-badge variant="muted">{{item.bookType | productName(true)}}</b-badge>
                <b-badge variant="muted">{{item.book.type | bookType}}</b-badge>
                <b-badge variant="muted">{{item.book.bindingName}}</b-badge>
              </template>
            </footer>
          </b-card>
        </template>
      </masonry>
    </section>

    <div class="btn-area" style="max-width: 480px;">
      <b-btn @click="loadMore" block v-if="!ended && !loadingMore" variant="success">点击获取更多晒单
      </b-btn>
      <loading v-if="loadingMore"/>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash'

import { fetchUserCount } from '@/ajax'

import routeData from '@/mixins/route-data'
import ImageZone from '@/components/ImageZone'

export default {
  name: 'unboxing',
  title: '书友晒单',
  components: {ImageZone},
  mixins: [routeData('unboxingItems'), routeData('rankCashback')],
  data() {
    return {
      page: 1,
      loadingMore: false,
      ended: false,
      latestUserCount: Math.pow(1024 * 2, 2),
      expanded: null,
      modalItem: {
        creator: {}
      }
    }
  },
  directives: {
    expand(el) {
      setTimeout(() => {
        if (el.scrollHeight > el.clientHeight) {
          el.nextElementSibling.style.display = 'block'
        }
      })
    }
  },
  computed: {
    sortRankCashback() {
      return orderBy(this.rankCashback, 'rank', 'desc').filter(i => i.rank > 0)
    },
    cashbacks() {
      return [...this.sortRankCashback, ...this.unboxingItems]
    },
    cols() {
      const innerWidth = this.$store.state.innerWidth
      if (innerWidth > 1024) {
        return 4
      }
      if (innerWidth > 768) {
        return 3
      }
      if (innerWidth > 425) {
        return 2
      }
      return 1
    }
  },
  methods: {
    loadMore() {
      this.loadingMore = true
      return this.fetchUnboxingItems({page: ++this.page}).then(data => {
        this.loadingMore = false
        if (data.length < 15) {
          this.ended = true
          return
        }
        this.unboxingItems = this.unboxingItems.concat(data)
      })
    }
  },
  created() {
    fetchUserCount().then(data => {
      this.latestUserCount = data
    })
  }
}
</script>

<style lang="scss" scoped>
.unboxing {
  position: relative;
  max-width: 100%;
  overflow: hidden;

  > header {
    position: relative;
    margin-bottom: 1em;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: -1.5rem;
    padding: 6rem 2rem;
    background-image: url(../../assets/images/banner-unboxing.jpg);
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left center;

    @include media-breakpoint-down(sm) {
      padding: 3rem 1rem;
      text-align: center;
    }

    .content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1280px;
    }

    .slogan {
      font-size: 1.5rem;
    }
  }

  .masonry {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }

  .card {
    margin: 0 auto 20px;
    overflow: hidden;

    .card-text {
      white-space: pre-line;
      cursor: default;
      pointer-events: none;
      user-select: none;

      &:not(.expanded) {
        @include line-clamp(6);
      }
    }

    .rank-3 {
      background-color: $blue;
    }

    .rank-2 {
      background-color: $orange;
    }

    .rank-1 {
      background-color: $gray-400;
      color: #666;
    }

    .card-footer {
      border-top: none;
    }
  }
}
</style>
